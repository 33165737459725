<template>
  <v-card>
    <v-card-text class="py-0">
      <!-- Data Table -->
      <v-col cols="12">
        <v-card flat>
          <v-data-table
            :headers="headers"
            :items="comprobantesDeuda"
            v-model="selected"
            show-select
            :search="search"
            :hide-default-footer="comprobantesDeuda.length === 1"
            item-key="compId"
            class="elevation-1"
            @toggle-select-all="selectAllToggle"
          >
            <template v-slot:[`item.saldo`]="{ item }">
              <span>{{ formatPrice(item.saldo) }}</span>
            </template>
            <template v-slot:[`item.totalComprobante`]="{ item }">
              <span>{{ formatPrice(item.totalComprobante) }}</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-card-title class="headline primary--text"
                      >Comprobantes de deuda</v-card-title
                    >
                  </v-col>
                </v-row>
              </v-toolbar>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="9">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template
              v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
            >
              <v-simple-checkbox
                :value="isSelected"
                :readonly="!item.mostrarComprobante"
                :disabled="!item.mostrarComprobante"
                color="primary"
                @input="select($event)"
                :ripple="false"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-form
        v-model="isFormValid"
        ref="form"
        form="form"
        id="form"
        @submit.prevent=""
        v-if="selected.length > 0"
      >
        <v-row class="pl-3" v-if="!comprobantesDeuda.existeConfiguracionCobro">
          <!-- Cajas -->
          <v-col cols="12" md="3" sm="12" class="py-0">
            <v-autocomplete
              v-model="cajaSelected"
              :items="cajas"
              label="Caja"
              item-text="value"
              item-value="id"
              outlined
              clearable
              return-object
              dense
              :rules="rules.required"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="pl-3">
          <!-- Fecha de cobro -->
          <v-col cols="12" sm="12" md="3" class="py-0">
            <v-menu
              ref="fecha-cobro"
              v-model="menuFechaCobro"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  ref="fecha-cobro-textField"
                  v-model="fechaCobroSelected"
                  label="Fecha de cobro"
                  :append-icon="calendarIcon"
                  hint="Formato DD/MM/AAAA"
                  v-mask="'##/##/####'"
                  @blur="fechaCobro = parseDateToIso(fechaCobroSelected)"
                  outlined
                  dense
                  clearable
                  v-bind="attrs"
                  :rules="rules.required.concat(rules.validDate)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaCobro"
                no-title
                @change="fechaCobroSelected = formatDate(fechaCobro)"
                @input="menuFechaCobro = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="pl-3">
          <!-- Formas de pago -->
          <v-col cols="12" sm="12" md="4" class="py-0">
            <v-autocomplete
              outlined
              clearable
              dense
              return-object
              :disabled="formaPagoAgenteCta"
              item-text="value"
              item-value="id"
              v-model="formaPagoSelected"
              label="Forma de pago"
              :rules="!formaPagoAgenteCta ? rules.required : []"
              :items="formasPago"
            ></v-autocomplete
          ></v-col>
          <v-col cols="12" md="5" class="py-0 text-right">
            <v-switch
              class="pt-0"
              v-model="formaPagoAgenteCta"
              label="Utilizar forma de pago del agente de cuenta"
              dense
              outlined
            ></v-switch>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <!-- Cantidad de comprobantes -->
        <v-col cols="12" sm="6" md="3" class="py-0 pl-7 pr-10">
          <v-text-field
            type="text"
            outlined
            dense
            disabled
            filled
            readonly
            class="theme--light.v-input input"
            label="Cantidad de comprobantes"
            v-model="selected.length"
            autocomplete="off"
          ></v-text-field>
        </v-col>
        <!-- Saldo total a cancelar -->
        <v-col cols="12" sm="6" md="3" class="py-0 pr-15">
          <v-text-field
            type="text"
            outlined
            dense
            disabled
            filled
            readonly
            class="theme--light.v-input input"
            label="Saldo total a cancelar"
            v-model="saldoTotalCancelar"
            autocomplete="off"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined class="to-right" @click="closeModal"> Cerrar </v-btn>
        <v-btn
          color="primary"
          @click="generarComprobantesCobro()"
          class="to-right"
          :loading="loadingRegistrarBtn"
          :disabled="
            !selected.length > 0 || !isFormValid || loadingRegistrarBtn
          "
        >
          Registrar
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  name: "BuscarDeudaModal",
  directives: { mask },
  props: {
    comprobantesDeuda: {
      type: Array,
      required: true
    },
    session: {
      type: Number,
      required: true
    }
  },
  data: vm => ({
    searchIcon: enums.icons.SEARCH,
    search: "",
    rules: rules,
    headers: [
      {
        text: "N° de documento",
        sortable: false,
        value: "dni"
      },
      {
        text: "Agente de cuenta",
        align: "start",
        sortable: false,
        value: "agectaNom"
      },
      {
        text: "Tipo de comprobante",
        sortable: false,
        value: "tipoCompNom"
      },
      {
        text: "Sucursal",
        sortable: false,
        value: "sucursal"
      },
      {
        text: "Número",
        sortable: false,
        value: "nroComprobante"
      },
      {
        text: "Vencimiento",
        sortable: false,
        value: "vencimiento"
      },
      {
        text: "Total",
        sortable: false,
        align: "end",
        value: "totalComprobante"
      },
      {
        text: "Saldo",
        sortable: false,
        align: "end",
        value: "saldo"
      },
      {
        text: "Observación",
        sortable: false,
        value: "observacion"
      }
    ],
    selected: [],
    disabledCount: 0,
    saldoTotalCancelar: null,
    cajas: [],
    cajaSelected: null,
    loadingRegistrarBtn: false,
    formasPago: [],
    formaPagoSelected: null,
    menuFechaCobro: false,
    fechaCobroSelected: vm.formatDate(new Date().toISOString().substr(0, 10)),
    fechaCobro: new Date().toISOString().substr(0, 10),
    calendarIcon: enums.icons.CALENDAR,
    isFormValid: false,
    formaPagoAgenteCta: false
  }),
  created() {
    this.comprobantesDeuda.map(item => {
      if (!item.mostrarComprobante) this.disabledCount += 1;
    });
    this.selectAllToggle();
    this.setSelects();
  },
  watch: {
    selected() {
      this.saldoTotalCancelar = null;
      this.selected.forEach(x => {
        this.saldoTotalCancelar += x.saldo;
      });
      this.saldoTotalCancelar = this.formatPrice(this.saldoTotalCancelar);
    },
    formaPagoAgenteCta() {
      if (this.formaPagoAgenteCta) this.formaPagoSelected = null;
    }
  },
  methods: {
    ...mapActions({
      getCajasXUsuario: "caja/getCajasXUsuario",
      getFormasDePago: "devengamientos/getFormasDePago",
      generarComprobantesDeudaCobro: "caja/generarComprobantesDeudaCobro",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const cajas = await this.getCajasXUsuario();
      this.cajas = cajas;
      this.cajaSelected = cajas[0];
      const formasPago = await this.getFormasDePago();
      this.formasPago = formasPago;
      const findFPagoContado = this.formasPago.find(x => x.value === "Contado");
      if (findFPagoContado != null) this.formaPagoSelected = findFPagoContado;
    },
    formatPrice(importe) {
      const formatPrice = new Intl.NumberFormat("es-AR");
      const importeFormatted = formatPrice.format(importe);
      return importeFormatted;
    },
    closeModal() {
      this.loadingRegistrarBtn = false;
      this.$emit("toggleModalBuscarDeuda");
    },
    selectAllToggle() {
      if (
        this.selected.length !=
        this.comprobantesDeuda.length - this.disabledCount
      ) {
        this.selected = [];
        this.comprobantesDeuda.forEach(item => {
          if (item.mostrarComprobante) {
            this.selected.push(item);
          }
        });
        this.setAlert({
          type: "info",
          message:
            "Todos los comprobantes no anulados/cancelados han sido seleccionados."
        });
      } else if (this.comprobantesDeuda.length === this.disabledCount) {
        this.selected = [];
        this.setAlert({
          type: "warning",
          message: "No se puede seleccionar ningún comprobante."
        });
      } else {
        this.selected = [];
        this.setAlert({
          type: "info",
          message: "Todos los comprobantes han sido deseleccionados."
        });
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    async generarComprobantesCobro() {
      this.loadingRegistrarBtn = true;
      const data = {
        comprobantesDeuda: this.selected,
        datosComprobanteCobro: {
          cajaId: this.cajaSelected.id,
          cajaNom: this.cajaSelected.value,
          fechaCobro: this.fechaCobro,
          formaPagoId:
            this.formaPagoSelected != null ? this.formaPagoSelected.id : null,
          formaPagoNom:
            this.formaPagoSelected != null
              ? this.formaPagoSelected.value
              : null,
          habilitaFormaPago: this.formaPagoAgenteCta
        }
      };
      try {
        const generarComprobantes = await this.generarComprobantesDeudaCobro({
          sessionId: this.session,
          params: data,
          esProceso: false
        });
        this.$emit("setComprobantesCobros", generarComprobantes);
        this.closeModal();
      } catch {
        this.loadingRegistrarBtn = false;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep .v-input__slot label {
  margin-bottom: 0% !important;
}
</style>
